import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import Analytics1 from "../assets/images/about-one.png";
import Analytics2 from "../assets/images/hosting-banner-image/static-main.png";

const Analytics = () => (
    <Layout>
        <SEO title="Machine Learning & Big Data Analytics" /> 

        <Navbar />

        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                    <div className="features-details-desc">
                    <h3>Machine Learning & Big Data Analytics</h3>
                    <p>Our data scientists are capable of processing data at scale with machine learning algorithms to identify patterns that can aid your business.</p>

                    <ul className="features-details-list">
                        <li>Predictive Analytics for Business Use Cases</li>
                        <li>Machine Vision Algorithms</li>
                        <li>Natural Language Processing</li>
                        <li>Optimization</li>
                        <li>Data Visualization & Dashboarding</li>
                    </ul>
                </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Analytics1} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Analytics2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                        <p>Trained in the cutting edge of data science, our analysts leverage relevant approaches and tools to ensure that our clients get the most out of their data. </p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                What Tools Do You Use?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Our data scientists use both R and Python and have expertise in many common packages, such as PyTorch, TensorFlow, scikit-learn, Numpy, and Pandas. For data-visualization, our team leverages PowerBI, Tableau, and web-based frameworks, such as D3.JS.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Can You Work With Health Data?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, our team can work with sensitive data, but will need to facilitate the analysis in an appropriate and de-identified manner. Our team is knowledgeable on HIPAA and other regulations around data privacy and security.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Do I Need To Provide the Data?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Usually, yes. In order for us to model your data to meet your desired outcome, our team will need to leverage the domain-specific data you have gathered. Depending on the use case, our team can identify and gather publicly available data to build analytical models to meet your needs.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Analytics;